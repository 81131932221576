exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-conditions-generales-de-ventes-js": () => import("./../../../src/pages/legal/conditions-generales-de-ventes.js" /* webpackChunkName: "component---src-pages-legal-conditions-generales-de-ventes-js" */),
  "component---src-pages-legal-mentions-legales-js": () => import("./../../../src/pages/legal/mentions-legales.js" /* webpackChunkName: "component---src-pages-legal-mentions-legales-js" */),
  "component---src-pages-manifeste-js": () => import("./../../../src/pages/manifeste.js" /* webpackChunkName: "component---src-pages-manifeste-js" */),
  "component---src-pages-produits-index-js": () => import("./../../../src/pages/produits/index.js" /* webpackChunkName: "component---src-pages-produits-index-js" */),
  "component---src-pages-produits-shopify-collection-handle-js": () => import("./../../../src/pages/produits/{ShopifyCollection.handle}.js" /* webpackChunkName: "component---src-pages-produits-shopify-collection-handle-js" */),
  "component---src-pages-produits-shopify-product-handle-js": () => import("./../../../src/pages/produits/{ShopifyProduct.handle}.js" /* webpackChunkName: "component---src-pages-produits-shopify-product-handle-js" */),
  "component---src-pages-sur-place-js": () => import("./../../../src/pages/sur-place.js" /* webpackChunkName: "component---src-pages-sur-place-js" */)
}

